export const WORDS = [
  "hello",
  "frost",
  "finch",
  "swaga",
  "apush",
  "rally",
  "guild",
  "coach",
  "dorms",
  "stuco",
  "games",
  "booth",
  "music",
  "shows",
  "visit",
  "apbio",
  "moody",
  "teams",
  "kobus",
  "dance",
  "house",
  "jobro",
  "peter",
  "quigs",
  "essay",
  "satow",
  "chris",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "xxxxx",
  "teddy",
  "apgov",
  "stage",
  "stern",
  "wotty",
  "lunch",
  "hmart",
  "fitzy",
  "jamie",
  "marsh",
  "block",
  "allen",
  "reyes",
  "trips",
  "slide",
  "psych",
  "aplit",
  "micro",
  "karin",
  "tests",
  "dhall",
  "delay",
  "masks",
  "suomi",
  "clubs",
  "ogden",
  "tabor",
  "remis",
  "latin",
  "learn",
  "neuro",
  "soule",
  "reach",
  "kagwa",
  "aliis",
  "gofar",
  "grade",
  "covid",
  "earth",
  "songs",
  "bands",
  "email",
  "exams",
  "pesky",
  "sager",
  "event",
  "field",
  "video",
  "eames",
  "eddie",
  "grill",
  "tardy",
  "noyes",
  "sport",
  "night",
  "movie",
  "brace",
  "zooms",
  "dolan",
  "class",
  "drama",
  "kelly",
  "tober",
  "gerry",
  "oxton",
  "aleem",
  "macro",
  "gowns",
  "morse",
  "grads",
  "final",
  "study",
  "teach",
  "tired",
  "break",
]
